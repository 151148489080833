import React from "react"
import PropTypes from "prop-types"
import { TransitionGroup, Transition } from "react-transition-group"

const PageTransition = props => {
  const {
    children,
    location,
    timeout,
    transitionStyles,
    className,
    style,
  } = props

  return (
    <TransitionGroup>
      <Transition
        key={location}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {status => (
          <div
            className={className}
            style={{
              ...style,
              ...transitionStyles[status],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

PageTransition.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.string,
  timeout: PropTypes.number,
  transitionStyles: PropTypes.shape({
    entering: PropTypes.object,
    entered: PropTypes.object,
    exiting: PropTypes.object,
  }),
}

PageTransition.defaultProps = {
  location: "",
  timeout: 250,
  transitionStyles: {
    entering: {
      position: `absolute`,
      opacity: 0,
    },
    entered: {
      transition: `opacity 250ms ease-in-out`,
      opacity: 1,
    },
    exiting: {
      transition: `opacity 250ms ease-in-out`,
      opacity: 0,
    },
  },
}
export default PageTransition
