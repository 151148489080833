export default {
  breakpoints: {
    medium: "80em",
    large: "90em",
    hd: "120em",
  },
  fontSizes: {
    medium: {
      title: "32px",
      menu: "14px",
      link: "14px",
      description: "12px",
      subtitle: "28px",
      heading: "10px",
    },
    large: {
      title: "52px",
      menu: "16px",
      link: "16px",
      description: "14px",
      subtitle: "36px",
      heading: "14px",
    },
  },
  colors: {
    background: "#19211E",
    text: "#6B7C73",
    hover: "#79a187",
    menu: "#8A918C",
    button: "#84998B",
  },
  space: {
    gutter: "20px",
  },
  fonts: {
    body: "Graphie",
  },
  fontWeights: {},
  lineHeights: {},
  zIndices: {
    loading: 9999,
    menu: 1000,
    folio: 800,
    actions: 500,
    arrows: 199,
    infos: 200,
  },
  border: "1px solid #545E58",
}
