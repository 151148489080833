import React from "react"
import styled from "@emotion/styled"
import Container from "@src/components/Container"

const Header = styled(props => <Container as="header" {...props} />)`
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 21px;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    height: 85px;
  }
`

export default ({ children, ...rest }) => <Header {...rest}>{children}</Header>
