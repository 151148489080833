import React, {
  Component,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react"

const MenuContext = createContext({
  active: false,
  open: () => {},
  close: () => {},
})

const MenuProvider = props => {
  // States
  const [active, setActive] = useState(false)

  // Actions
  const open = useCallback(() => setActive(true), [setActive])
  const close = useCallback(() => setActive(false), [setActive])

  return (
    <MenuContext.Provider
      value={{
        active,
        open,
        close,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  )
}

export default MenuProvider
export { MenuContext }
