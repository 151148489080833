import React, { useContext, useEffect, useMemo, useState } from "react"
import Menu from "@src/components/menu"
import PageTransition from "@src/components/transition/PageTransition"
import styled from "@emotion/styled"
import withLoading, { LoadingContext } from "@src/contexts/LoadingContext"
import LoadingIcon from "@src/assets/icons/loading.inline.svg"
import BookIcon from "@src/assets/icons/book.svg"
import DisclaimerIcon from "@src/assets/icons/disclaimer.svg"
import DisclaimerTextIcon from "@src/assets/icons/disclaimer-text.svg"
import Logo from "@src/assets/icons/logo.svg"
import menuBackIcon from "@src/assets/icons/back.svg"
import SEO from "@src/components/seo"

const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.background};
  z-index: ${props => props.theme.zIndices.loading};
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.isLoaded {
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms;
  }

  img {
    width: 60px;
  }

  svg {
    width: 64px;
    height: 64px;
    margin-top: 40px;
  }

  span {
    padding-top: 15px;
    font-size: 28px;
    text-transform: uppercase;
    font-family: "Institut De France";
  }

  @-webkit-keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rotating2 {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes rotating2 {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  svg {
    .rotate1 {
      transform-origin: center;
      animation: rotating 2s linear infinite;
    }

    .rotate2 {
      transform-origin: center;
      animation: rotating2 2s linear infinite;
    }
  }
`

const WrapperPageTransition = styled(PageTransition)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`

const Disclaimer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: ${props => props.theme.colors.background};
  display: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > img {
    width: 60px;
  }

  p {
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  @media (max-width: 1023px) {
    display: flex;
  }
`

const DisclaimerButton = styled.a`
  border: none;
  background-color: transparent;
  margin-top: 20px;
  text-align: center;

  img {
    height: 28px;
    display: inline-block;

    &:first-of-type {
      padding-right: 10px;
    }
  }
`

const timeout = 500

const transitionStyles = {
  entering: {
    position: "fixed",
    opacity: 0,
  },
  entered: {
    transition: `opacity 250ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity 250ms ease-in-out`,
    opacity: 0,
  },
}

const WrapPage = props => {
  const { children, location } = props

  const { activeLoading, startLoading } = useContext(LoadingContext)

  const name = props.pageContext && props.pageContext.name

  const [needLoading, setNeedLoading] = useState(false)

  useEffect(() => {
    if (location.state && !location.state.override) setNeedLoading(true)
  }, [location, setNeedLoading])

  useEffect(() => {
    if (!activeLoading && needLoading) startLoading()
    else if (activeLoading && needLoading) setNeedLoading(false)
  }, [activeLoading, needLoading, startLoading, setNeedLoading])

  return (
    <WrapperPageTransition
      location={
        (location.state && location.state.override) || location.pathname
      }
      transitionStyles={transitionStyles}
      timeout={timeout}
    >
      <>
        <SEO />
        <Disclaimer>
          <img src={DisclaimerIcon} alt="" />
          <p>
            Expérience disponible
            <br />
            uniquement sur
            <br />
            ordinateur et tablette
          </p>
          <DisclaimerButton href="https://www.institutdefrance.fr/">
            <img src={menuBackIcon} alt="" />
            <br />
            <img src={DisclaimerTextIcon} alt="" />
          </DisclaimerButton>
        </Disclaimer>
        <Loading className={activeLoading ? "" : "isLoaded"}>
          <img src={BookIcon} alt="" />
          <span>{name ? `Le carnet ${name}` : "Menu des feuilletoirs"}</span>
          <LoadingIcon />
        </Loading>
        <Menu location={props.location} />
        {children}
      </>
    </WrapperPageTransition>
  )
}

export default withLoading(WrapPage)
