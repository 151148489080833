import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import styled from "@emotion/styled"

const LoadingContext = createContext({
  activeLoading: true,
  startLoading: () => {},
  stopLoading: () => {},
})

const LoadingProvider = props => {
  // States
  const [active, setActive] = useState(true)
  const [debounce, setDebounce] = useState(true)

  // Actions
  const debouncing = useCallback(() => {
    setTimeout(() => setDebounce(false), 2000)
  }, [setDebounce])

  const startLoading = useCallback(() => {
    setActive(true)
    setDebounce(true)
    debouncing()
  }, [setActive, debouncing])

  const stopLoading = useCallback(() => {
    setActive(false)
  }, [setActive])

  const activeLoading = useMemo(() => active || debounce, [active, debounce])

  useEffect(() => {
    debouncing()
  }, [debouncing])

  return (
    <LoadingContext.Provider
      value={{
        activeLoading: activeLoading,
        startLoading,
        stopLoading,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  )
}

const withLoading = Component => props => (
  <LoadingProvider>
    <Component {...props} />
  </LoadingProvider>
)

export { LoadingContext, LoadingProvider }
export default withLoading
