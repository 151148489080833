import React, { useCallback, useContext, useMemo } from "react"
import styled from "@emotion/styled"
import { MenuContext } from "@src/contexts/MenuContext"
import menuBackIcon from "@src/assets/icons/back.svg"
import { navigate } from "gatsby"

const MenuButtonWrapper = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

const MenuButtonIcon = styled.img`
  display: block;
  width: 42px;
  height: 42px;
  margin-top: 6px;
  pointer-events: none;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    width: 36px;
    height: 36px;
  }
`

const MenuButton = props => {
  const { root } = props

  const goBack = useCallback(() => {
    if (root) {
      window.location.href =
        "https://www.institutdefrance.fr/lesdossiers/leonard-de-vinci-carnets"
    } else {
      return navigate("/", {
        replace: true,
      })
    }
  }, [root])

  return (
    <MenuButtonWrapper {...props} onClick={goBack}>
      {root ? (
        <MenuButtonIcon src={menuBackIcon} />
      ) : (
        <MenuButtonIcon src={menuBackIcon} />
      )}
    </MenuButtonWrapper>
  )
}
export default MenuButton
