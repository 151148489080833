import React from "react"
import styled from "@emotion/styled"
import copyrightIcon from "@src/assets/icons/copyright.svg"
import titleIcon from "@src/assets/icons/title.svg"

const Container = styled.main`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    padding-left: 80px;
    padding-right: 80px;
  }
`

const ContainerIcon = styled.img`
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  padding-bottom: 15px;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    left: 15px;
    width: 50px;
  }
`

export default ({ title, copyright, children, ...rest }) => (
  <Container {...rest}>
    {title && (
      <a href="https://www.institutdefrance.fr/">
        <ContainerIcon src={titleIcon} />
      </a>
    )}
    {copyright && <ContainerIcon src={copyrightIcon} />}
    {children}
  </Container>
)
