import React from "react"
import "normalize.css"
import { ThemeProvider } from "emotion-theming"
import preset from "@src/theme"
import { css, Global } from "@emotion/core"
import "@src/assets/fonts/Graphie/stylesheet.css"
import "@src/assets/icons/icons_stylesheet.css"
import "@src/assets/fonts/Institut De France/stylesheet.css"
import MenuProvider from "@src/contexts/MenuContext"

const WrapRoot = ({ children }) => {
  return (
    <ThemeProvider theme={preset}>
      <>
        <Global
          styles={theme => css`
            body {
              color: ${theme.colors.text};
              font-family: ${theme.fonts.body};
              background-color: ${theme.colors.background};
              margin: 0px;
            }

            * {
              box-sizing: border-box;
            }
          `}
        />
        <MenuProvider>{children}</MenuProvider>
      </>
    </ThemeProvider>
  )
}

export default WrapRoot
