import React, { useCallback, useContext, useMemo } from "react"
import styled from "@emotion/styled"
import MenuButton from "./MenuButton"
import Header from "@src/components/Header"
import Logo from "@src/assets/icons/logo.svg"
import { withPrefix } from "gatsby"

const MenuNav = styled(props => <Header {...props} as={"nav"} />)`
  width: 100%;
  align-items: center;
  padding-bottom: 0;
`

const MenuLink = styled.span`
  position: relative;
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.large.menu};
  color: ${props => props.theme.colors.menu};
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 45px;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
  background-image: url("${Logo}");
  
  @media (max-width: ${props => props.theme.breakpoints.large}) {
    font-size: ${props => props.theme.fontSizes.medium.menu};
  }
`

/*const MenuContent = styled(props => <Container as={"nav"} {...props} />)`
  background-color: yellow;
  flex: 1 0 0;
  pointer-events: all;
  position: relative;
  width: 100%;
`*/

const MenuHeader = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  background-color: rgba(19, 28, 24, 0.8);
  z-index: 1000;
  transition: transform 0.2s ease-out;
`

const MenuToggle = styled(MenuButton)`
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translate(-50%, -50%);
  pointer-events: all;
  z-index: 1100;

  .no-touchevents &:hover + ${MenuHeader} {
    transform: translateY(0);
  }

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    left: 42px;
  }
`

const MenuWrapper = styled(Header)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
`

const Menu = props => {
  const root = useMemo(() => props.location.pathname === withPrefix("/"), [
    props.location.path,
  ])

  return (
    <MenuWrapper {...props}>
      <MenuToggle root={root} />
      <MenuHeader>
        <MenuNav>
          {root ? (
            <MenuLink>Introduction du dossier</MenuLink>
          ) : (
            <MenuLink>Menu des feuilletoirs</MenuLink>
          )}
          {/*<MenuLink to={"/livre-L"}>
            Voir la vidéo de présentation des carnets
          </MenuLink>
          <MenuLink to={"/livre-B"}>
            Vers le site de l'institut de france
          </MenuLink>*/}
        </MenuNav>
      </MenuHeader>
      {/*<MenuContent style={{ display: active ? "block" : "none" }}>
        <ul>
          <li>
            <Link to={"/livre-L"}>Livre L</Link>
          </li>
          <li>
            <Link to={"/livre-B"}>Livre B</Link>
          </li>
          <li>
            <Link to={"/livre-G"}>Livre G</Link>
          </li>
        </ul>
      </MenuContent>*/}
    </MenuWrapper>
  )
}

export default Menu
